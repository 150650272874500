<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />
      <q-breadcrumbs-el label="States" to="/jobs/state" />
      <q-breadcrumbs-el :label="siteArrays && siteArrays.states && siteArrays.states[$route.params.stateCode]" />
    </q-breadcrumbs>

  <div class="row justify-between items-end q-mb-sm q-mt-md">
    <div class="col full-height text-h5">
      Total {{ siteArrays && siteArrays.states && siteArrays.states[$route.params.stateCode]}} Jobs: {{numRecs}} <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}} Jobs
      </span>
    </div>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>
  </div>

  <table class="table table-striped table-sm full-width">
    <job-list-item v-for="post in recs" :key="post._id" :post="post" view-route-name="jobStateView"></job-list-item>
  </table>
</div>
</template>

<script>
import jobModule from "../../../store";
import storeRecListMixin from "src/mixins/store_rec_list.mixin";
import { mapState } from 'vuex';

export default {
  mixins: [
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'posts',
      vuexCollection: 'job.recs',
      vuexPath: 'job',
      vuexPageRequestAction: jobModule.actionTypes.LoadRecordsPageRequest,

    }
  },
  computed: {
      ...mapState('site', {
        siteArrays: 'arrs',
      }),
      // ...mapGetters('post', [
      //   'hasPageSet',
      // ]),
      // currentPage() {
      //   return +this.$route.params.pageId;
      // },
      // numPages() {
      //   return Math.ceil(this.numJobs / 25);
      // },
      // stateCode() {
      //   return this.$route.params.stateCode;
      // },
      // posts() {
      //   return this.$store.getters['post/getPageSet'](this.currentPage);
      // },
  },

  watch: {
    // call again the method if the route changes
    // '$route': function() { this.reqData(); },
  },

  created () {
    // this.reqData();
  },

  methods: {
    bookmark (postId) {
      console.log('bookmark post', postId);
    },
    // gotoPage (pageId) {
    //   this.$store.dispatch( 'post/' + PostActionTypes.PostsLoadRecordsPageRequest, { page_id: +pageId,  qry: { 'location.state_code': this.$route.params.stateCode } } );

    //   this.$router.push({ params: { pageId }});
    // },
    // reqData (force) {
    //   if (! this.hasPageSet(this.$route.params.pageId, this.qry) ||  force ) {
    //     const payload = {
    //       page_id: +this.$route.params.pageId,
    //       qry: this.qry
    //     };

    //     this.$store.dispatch( 'post/' + PostActionTypes.PostsLoadRecordsPageRequest, payload);
    //   }
    // },
    gotoPage (pageId) {
      this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { page_id: +pageId,  qry: { 'location.state_code': this.$route.params.stateCode } } );

      this.$router.push({ params: { pageId }});
    },
    reqData (force) {
      if (! this.hasPageSet(this.$route.params.pageId, this.qry) ||  force ) {
        const payload = {
          page_id: +this.$route.params.pageId,
          qry: { 'location.state_code': this.$route.params.stateCode }
        };

        this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, payload);
      }
    },

  },

}
</script>



